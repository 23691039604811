export async function commonFecth<T> (
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
  body?: RequestInit['body'] | Record<string, any>,
  accessToken? :string
): Promise<T> {
  const config = useRuntimeConfig()
  const state = useLoginAccount()
  const uri = path.replace(/^\/+/, '')
  const data = await $fetch<T>(`${config.public.baseUrl}/${uri}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken || state.value.accessToken}`
    },
    body: JSON.stringify(body),
    onResponse ({ request, response, options }) {
      // console.log('response.status', response.status)

    },
    onResponseError (context) {
      if (context.response.status === 401) {
        useSession().clear()
        useLoginDialog().value = true
        return Promise.reject(new Error('401 Unauthorized'))
      }
      console.error(context.error)
      return Promise.reject(new Error(String(context.response.status)))
    }
  })

  return data as T
}
